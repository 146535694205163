import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/fixedAsset'

export default {
  get (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  process (params) {
    return httpClient.post(`${endpoint}`, params)
  }
}

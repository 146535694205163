<template>
  <div class="no-scroll-content flex flex-col border border-solid d-theme-border-grey-light d-theme-dark-bg">
    <!------------ box header ------------->
    <div class="flex-none">
      <div class="flex flex-wrap space-x-3 p-2 vx-card shadow-none">
        <div class="flex items-center">
          <label class="mr-1 text-xs whitespace-no-wrap">Proyek</label>
          <vs-select class="vs-select-small" v-model="filter.id_proyek">
            <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
          </vs-select>
        </div>
        <div class="flex items-center">
          <label class="mr-1 text-xs whitespace-no-wrap">Tgl. Proses</label>
          <vx-input-group>
            <template slot="prepend">
              <vx-input-group>
                <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.tgl_proses"></flat-pickr>
              </vx-input-group>
            </template>
          </vx-input-group>
        </div>
        <div class="flex items-center">
          <vs-button class="px-7 py-3 text-xs text-center" color="primary" :disabled="loading.filtering" @click="refresh">
            {{ loading.filtering ? 'Loading...' : 'Filter' }}
          </vs-button>
        </div>
      </div>
      <div class="flex justify-between border-t border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
        <div class="flex items-center justify-start space-x-3 px-2 h-8">
          <p class="text-xs font-medium text-dark text-bold">{{ this.data.items.length }} Result</p>
          <QueryEditor v-if="$can('view_query')" code="FIXED_ASSET"/>
        </div>
      </div>
    </div>

    <!----------- box content ------------->
    <div class="flex-1 border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
      <div class="box-content-height overflow-auto">
        <div class="tableSticky">
          <!--<table class="table-fixed table-sticky border-collapse w-full">-->
          <table class="w-full h-full border-collapse">
            <!-----------TABLE HEAD------------>
            <thead>
              <tr class="d-theme-dark-bg">
                <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-8">#</th>
                <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-40">Nama Asset</th>
                <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-72">Account Debit</th>
                <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-72">Account Credit</th>
                <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-26">Last Process Date</th>
                <th class="border border-solid d-theme-border-grey-light px-2 text-xs text-center w-32">Total to Process</th>
              </tr>
            </thead>
            <!-----------TABLE BODY------------>
            <tbody>
              <tr v-for="(item, index) in data.items" :key="index">
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-center">{{ index + 1 }}</td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-left">{{ item.nama }}</td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-left">{{ item.kode_coa_debet }} - {{ item.nama_coa_debet }}</td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-left">{{ item.kode_coa_kredit }} - {{ item.nama_coa_kredit }}</td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-center">{{ item.last_process || '-' }}</td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right">{{ item.total | idr }}</td>
              </tr>
              <!--spacer-->
              <tr v-if="data.items.length > 0" class="h-full">
                <td colspan="6"></td>
              </tr>
            </tbody>
            <!-----------TABLE FOOT------------>
            <tfoot v-if="data.items.length > 0">
              <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light text-xs px-2 text-xs text-right">{{ summary.grandTotal | idr }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <!------------ box footer ------------->
    <div class="flex-none">
      <div class="flex flex-wrap items-center p-2 justify-between">
        <div class="flex items-center">
          <vs-button v-if="$can('process_fixed_asset')" color="success" size="small" class="px-3 mr-2" icon-pack="feather" icon="icon-check-square" :disabled="loading.processing || data.items.length < 1" @click="confirmProcess">
            {{ loading.processing ? 'Processing...' : `Process` }}
          </vs-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import FixedAssetRepository from '@/repositories/accounting/fixed-asset-repository'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'FixedAsset',
  components: {
    flatPickr,
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor')
  },
  mounted () {
    this.getProyek()
  },
  computed: {
    summary () {
      return {
        grandTotal: _.sumBy(this.data.items, item => parseFloat(item.total))
      }
    }
  },
  data () {
    return {
      loading: {
        filtering: false,
        processing: false
      },
      filter: {
        id_proyek: null,
        tgl_proses: moment().startOf('day').format('YYYY-MM-DD')
      },
      data: {
        items: []
      },
      proyeks: []
    }
  },
  methods: {
    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getData () {
      this.loading.filtering = true

      const params = {
        id_proyek: this.filter.id_proyek,
        tgl_proses: this.filter.tgl_proses
      }
      FixedAssetRepository.get(params)
        .then(response => {
          this.data.items = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.filtering = false
        })
    },

    process () {
      this.loading.processing = true

      const params = {
        id_proyek: this.filter.id_proyek,
        tgl_proses: this.filter.tgl_proses
      }
      FixedAssetRepository.process(params)
        .then(response => {
          this.notifySuccess('Berhasil memproses')
          this.refresh()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.notifyErrorUsingDialog(error.response.data.errors)
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.loading.processing = false
        })
    },

    refresh () {
      this.getData()
    },

    confirmProcess () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: 'Konfirmasi',
        text: 'Yakin ingin memproses?',
        acceptText: 'Ya, Lanjutkan',
        cancelText: 'Batal',
        accept: () => {
          this.process()
        }
      })
    }
  }
}
</script>

<style scoped>
/*navbar floating*/
.navbar-floating .box-content-height {
  height: calc(100vh - 283px);
}
.navbar-floating.footer-sticky .box-content-height {
  height: calc(100vh - 306px);
}
.navbar-floating.footer-hidden .box-content-height {
  height: calc(100vh - 252px);
}

/*navbar sticky*/
.navbar-sticky .box-content-height {
  height: calc(100vh - 269px);
}
.navbar-sticky.footer-sticky .box-content-height {
  height: calc(100vh - 292px);
}
.navbar-sticky.footer-hidden .box-content-height {
  height: calc(100vh - 238px);
}

/*navbar static*/
.navbar-static .box-content-height {
  height: calc(100vh - 251px);
}
.navbar-static.footer-sticky .box-content-height {
  height: calc(100vh - 274px);
}
.navbar-static.footer-hidden .box-content-height {
  height: calc(100vh - 220px);
}

/*navbar hidden*/
.navbar-hidden .box-content-height {
  height: calc(100vh - 206px);
}
.navbar-hidden.footer-sticky .box-content-height {
  height: calc(100vh - 229px);
}
.navbar-hidden.footer-hidden .box-content-height {
  height: calc(100vh - 175px);
}

/*table sticky*/
.tableSticky {
  overflow: auto;
  height: calc(100vh - 305px);
}
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 101;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 101;
}
</style>
